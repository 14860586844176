import { Component, Input } from '@angular/core';

@Component({
  selector: 'share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
  animations: [ ],
})
export class ShareButtonsComponent {

  @Input() webpageCDN: any;
  @Input() item: any;
  shareUrl: any;

  constructor() { }

  setShareUrl() {
    let toEncodeUrl = this.webpageCDN + '/' + this.item.path;
    this.shareUrl = encodeURIComponent(toEncodeUrl);
  }


  shareViaFacebook() {
    this.setShareUrl();
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl};src=sdkpreparse`;
    window.open(facebookUrl, '_blank');
  }

  shareViaX() {
    this.setShareUrl();
    const twitterUrl = `https://x.com/intent/tweet?url=${this.shareUrl}`;
    window.open(twitterUrl, '_blank');
  }

  shareViaPinterest() {
    this.setShareUrl();
    let toEncodeImageUrl = this.webpageCDN + '/assets/images/' + this.item.image;
    let imageUrl = encodeURIComponent(toEncodeImageUrl);

    const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${this.shareUrl}&media=${imageUrl}&method=button&description=${this.item.name}`;
    window.open(pinterestUrl, '_blank');
  }

  shareViaWhatsApp() {
    this.setShareUrl();
    const whatsAppUrl = `https://wa.me/?text=${this.shareUrl}`;
    window.open(whatsAppUrl, '_blank');
  }

  shareViaEmail() {
    this.setShareUrl();
    const emailUrl = `mailto:?subject=${this.item.name}&body=${this.shareUrl}`;
    window.location.href = emailUrl;
  }

}
