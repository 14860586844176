import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { TextService } from 'src/services/text.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  animations: [ ]
})
export class TopHeaderComponent implements OnInit, OnDestroy {

  categories: any[] = [];
  searchbarInput: any = '';
  clearSearchText$: any;
  isDesktop: boolean;
  isMobile = true;
  menuOpen = false;
  searchOpen = false;

  constructor(
    private platform: Platform,
    private router: Router,
    public webpageService: WebpageService,
    public text: TextService) {

    this.isDesktop = this.platform.is('desktop');
      if (this.platform.width() >= 992) {
        this.isMobile = false;
      }
    }
  goTo(event: MouseEvent, path: string) {
    //console.log(path);
    event.stopPropagation(); // Stop event propagation
    this.router.navigateByUrl(path); // Navigate to the specified path
    this.toggleMenu();
  }

  getColorForCategory(index:any) {
    if(index === 0) { return 'rgba(167, 199, 231, 0.2)'}
    if(index === 1) { return 'rgba(255, 127, 80, 0.2)'}
    if(index === 2) { return 'rgba(178, 240, 182, 0.2)'}
    if(index === 3) { return 'rgba(255, 192, 203, 0.2)'}
    else {
      return 'rgba(167, 199, 231, 0.2)';
    }
  }

  toggleMenu() { this.menuOpen = !this.menuOpen; }

  toggleSearch() {
    this.webpageService.showSearchBar = !this.webpageService.showSearchBar;
  }

  async ngOnInit() {
    this.clearSearchText$ = this.webpageService.clearSearchSubject.subscribe((clear:any) => {
      if (clear) {
        this.searchbarInput = '';
        this.webpageService.clearCompleted();
      }
    });
    this.categories = await this.webpageService.getItem(`topmenu`, `topmenu`);

  }

  openItem(category:any) {
    console.log(category);
    const navigationExtras: NavigationExtras = { state: { category }};
    this.router.navigate(['/' + category.path], navigationExtras);
  }

  clearSearchbar() {
    if (!this.isDesktop) {
    // npm install @capacitor/keyboard
    // npx cap sync
    // this.keyboard.hide();
    }
  }

  searchItem(event: any) {
    this.searchbarInput = event.detail.value;
    this.webpageService.search(this.searchbarInput);
  }

  ngOnDestroy() { this.clearSearchText$.unsubscribe(); }

}
