<div class="top-header-space"></div>
<ion-content>
  <ion-list class="default-list" *ngIf="searchbarInputLength === 0" role="list">

    <div class="h-subheading-container background-sectionA" role="listitem">
      <h1>{{text.home.subheadingSectionA}}</h1>
    </div>
    <div class="h-container background-sectionA" role="listitem">
      <div class="h-single-container" aria-hidden="true">
        <a role="button" class="h-item zoom-effect sectionA" *ngFor="let item of articlesSectionA | slice:0:1"
          [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" tabindex="0">
          <div class="default-image-container">
            <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
            <ion-img alt="{{item?.title}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <span class="h-title-promoted fadeIn">{{item.title}}</span>
          <span class="h-extract">{{item.extract}}</span>
        </a>
      </div>
      <div class="h-grid-container" aria-hidden="true">
        <a class="h-item sectionA zoom-effect" role="button" *ngFor="let item of articlesSectionA | slice:1:5"
          [routerLink]="'/' + item.path" [state]="{data: item}" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
            <div class="default-image-container">
              <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
              <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
            </div>
            <span class="h-title fadeIn">{{item.title}}</span>
        </a>
      </div>
    </div>
    <div class="h-titles-list background-sectionA" role="listitem">
      <a class="sectionA zoom-effect" *ngFor="let item of articlesSectionA | slice:5:8"
        [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
      </a>
    </div>
    <div class="h-button-container background-sectionA" role="listitem">
      <ion-button class="button-sectionA" [routerLink]="'/home'"  expand="block" fill="solid">
        {{text.home.buttonTextSectionA}}
      </ion-button>
    </div>

    <div class="h-subheading-container background-sectionB" role="listitem">
      <h1>{{text.home.subheadingSectionB}}</h1>
    </div>
    <div class="h-container background-sectionB">
      <div class="h-grid-container h-order-1" aria-hidden="true">
        <a class="h-item sectionB zoom-effect" *ngFor="let item of articlesSectionB | slice:1:5"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
          <div class="default-image-container">
            <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
            <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <span class="h-title fadeIn">{{item.title}}</span>
        </a>
      </div>
      <div class="h-single-container h-order-2" aria-hidden="true">
        <a class="h-item sectionB zoom-effect" *ngFor="let item of articlesSectionB | slice:0:1"
        [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
          <div class="default-image-container">
            <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
            <ion-img alt="{{item?.title}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <span class="h-title-promoted fadeIn">{{item.title}}</span>
          <span class="h-extract">{{item.extract}}</span>
        </a>
      </div>
    </div>
    <div class="h-titles-list background-sectionB" role="listitem">
      <a class="sectionB zoom-effect" *ngFor="let item of articlesSectionB | slice:5:8"
        [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
      </a>
    </div>
    <div class="h-button-container background-sectionB" role="listitem">
      <ion-button class="button-sectionB" [routerLink]="'/electronics'"  expand="block" fill="solid">
        {{text.home.buttonTextSectionB}}
      </ion-button>
    </div>

    <div class="h-subheading-container background-sectionC" role="listitem">
      <h1>{{text.home.subheadingSectionC}}</h1>
    </div>
    <div class="h-container background-sectionC">
      <div class="h-single-container" aria-hidden="true">
        <a class="h-item zoom-effect sectionC" *ngFor="let item of articlesSectionC | slice:0:1"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
            <div class="default-image-container">
              <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
              <ion-img alt="{{item?.title}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
            </div>
            <span class="h-title-promoted fadeIn">{{item.title}}</span>
            <span class="h-extract">{{item.extract}}</span>
        </a>
      </div>
      <div class="h-grid-container" aria-hidden="true">
        <a class="h-item sectionC zoom-effect" *ngFor="let item of articlesSectionC | slice:1:5"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
            <div class="default-image-container">
              <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
              <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
            </div>
            <span class="h-title fadeIn">{{item.title}}</span>
        </a>
      </div>
    </div>
    <div class="h-titles-list background-sectionC" role="listitem">
      <a class="sectionC zoom-effect" *ngFor="let item of articlesSectionC | slice:5:8"
        [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0">
          <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
      </a>
    </div>
    <div class="h-button-container background-sectionC" role="listitem">
      <ion-button class="button-sectionC" [routerLink]="'/garden-tools'"  expand="block" fill="solid">
        {{text.home.buttonTextSectionC}}
      </ion-button>
    </div>

    <div class="h-subheading-container background-sectionD" role="listitem">
      <h1>{{text.home.subheadingSectionD}}</h1>
    </div>
    <div class="h-container background-sectionD">
      <div class="h-grid-container h-order-1" aria-hidden="true">
        <a class="h-item sectionD zoom-effect" *ngFor="let item of articlesSectionD | slice:1:5"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
          <div class="default-image-container">
            <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
            <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <span class="h-title fadeIn">{{item.title}}</span>
        </a>
      </div>
      <div class="h-single-container h-order-2" aria-hidden="true">
        <a class="h-item sectionD zoom-effect" *ngFor="let item of articlesSectionD | slice:0:1"
        [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
          <div class="default-image-container">
            <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
            <ion-img alt="{{item?.title}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <span class="h-title-promoted fadeIn">{{item.title}}</span>
          <span class="h-extract">{{item.extract}}</span>
        </a>
      </div>
    </div>
    <div class="h-titles-list background-sectionD" role="listitem">
      <a class="sectionD zoom-effect" *ngFor="let item of articlesSectionD | slice:5:8"
        [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
      </a>
    </div>
    <div class="h-button-container background-sectionD" role="listitem">
      <ion-button class="button-sectionD" [routerLink]="'/sports-travel'"  expand="block" fill="solid">
        {{text.home.buttonTextSectionD}}
      </ion-button>
    </div>

    <div class="h-subheading-container background-sectionE" role="listitem">
      <h1>{{text.home.subheadingSectionE}}</h1>
    </div>
    <div class="h-container background-sectionE">
      <div class="h-single-container" aria-hidden="true">
        <a class="h-item zoom-effect sectionE" *ngFor="let item of articlesSectionE | slice:0:1"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
            <div class="default-image-container">
              <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
              <ion-img alt="{{item?.title}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
            </div>
            <span class="h-title-promoted fadeIn">{{item.title}}</span>
            <span class="h-extract">{{item.extract}}</span>
        </a>
      </div>
      <div class="h-grid-container" aria-hidden="true">
        <a class="h-item sectionE zoom-effect" *ngFor="let item of articlesSectionE | slice:1:5"
          [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
            <div class="default-image-container">
              <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
              <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
            </div>
            <span class="h-title fadeIn">{{item.title}}</span>
        </a>
      </div>
    </div>
    <div class="h-titles-list background-sectionE" role="listitem">
      <a class="sectionE zoom-effect" *ngFor="let item of articlesSectionE | slice:5:8"
        [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
      </a>
    </div>
    <div class="h-button-container background-sectionE" role="listitem">
      <ion-button class="button-sectionE" [routerLink]="'/health-wellness'"  expand="block" fill="solid">
        {{text.home.buttonTextSectionE}}
      </ion-button>
    </div>

  </ion-list>

  <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
  <app-footer></app-footer>

</ion-content>
