import { Component } from '@angular/core';
import { InfiniteScrollCustomEvent} from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { TextService } from 'src/services/text.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  articlesSectionA: any[] = [];
  articlesSectionB: any[] = [];
  articlesSectionC: any[] = [];
  articlesSectionD: any[] = [];
  articlesSectionE: any[] = [];
  showData = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public text: TextService
  ) {

    this.route.url.subscribe(async segments => {

      this.webpage = await this.webpageService.getWebpageData();
      this.setMetaTagsForMainPage();
      this.webpageService.initializeSearchbar();
      let mainpage = await this.webpageService.getItem(`mainpage`, `mainpage`);

      this.articlesSectionA = mainpage['home'];
      this.articlesSectionA.forEach((article: any, index: number) => {
        if (index === 0) {
          const image = `assets/images/${article.image}`;
          this.preloadService.preloadImage(image);
        }
        if (index > 0 && index < 5) {
          let image = this.webpageService.transformFilename(article.image);
          article.img1x = `assets/images/${image}`;
          this.preloadService.preloadImage(article.img1x);
        }
      });
      this.articlesSectionB = mainpage['electronics'];
      this.articlesSectionB.forEach((article: any) => {
          let image = this.webpageService.transformFilename(article.image);
          article.img1x = `assets/images/${image}`;
      });
      this.articlesSectionC = mainpage['garden-tools'];
      this.articlesSectionC.forEach((article:any) => {
       let image = this.webpageService.transformFilename(article.image);
       article.img1x = `assets/images/${image}`;
      });
      this.articlesSectionD = mainpage['sports-travel'];
      this.articlesSectionD.forEach((article:any) => {
        let image = this.webpageService.transformFilename(article.image);
        article.img1x = `assets/images/${image}`;
      });
      this.articlesSectionE = mainpage['health-wellness'];
      this.articlesSectionE.forEach((article:any) => {
        let image = this.webpageService.transformFilename(article.image);
        article.img1x = `assets/images/${image}`;
      });
    })
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        let image = this.webpageService.transformFilename(searchbarItem.i);
        searchbarItem.img1x = `assets/images/${image}`;
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }


  setMetaTagsForMainPage() {
    this.title.setTitle(this.webpage.metaTitle);
    this.meta.updateTag({name:'google-site-verification', content: 'IRQ-d66KaXZc1lsw7Khx52uiZoh3cgzNEpvu99qG6tw' });
    this.meta.updateTag({name:'description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.webpage.metaKeywords});
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.metaWebpage});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.metaWebpage}` });
    this.meta.updateTag({name:'og:title', content: this.webpage.metaTitle });
    this.meta.updateTag({name:'og:description', content: this.webpage.metaDescription });
    this.meta.updateTag({name:'og:image', content: `${this.webpage.metaImage}`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.metaWebpage}`});
    this.meta.updateTag({name:'twitter:title', content: this.webpage.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `${this.webpage.metaImage300}` });
  }

  loadMoreData(ev:any) {
    if(!this.showData) {
      this.showData = true;
      (ev as InfiniteScrollCustomEvent).target.complete();
    }
  }

}
