import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  public routes = {
    contact: 'contact',
    privacyPolicy: 'privacy-policy',
    electronics: 'electronics',
    home: 'home', // 'home-appliances'
    sportsTravel: 'sports-travel',
    gardenTools: 'garden-tools',
    healthWellness: 'health-wellness',
    reviewsItemSlug: 'reviews/:itemSlug',
  }

  public topheader = {
    websiteName: 'TOP*REVIEWS',
    placeholderText: 'Search...',
    arialabel: 'Go to homepage',
    ariaButtonSearch: 'Search button',
    ariaButtonCloseSearch: 'Close search button',
    ariaButtonCloseMenu: 'Close menu button',
    ariaButtonMenu: 'Menu button',
  }

  public relatedArticles = {
    subheading: 'READ ALSO',
    ariaLabel: 'Related article: '
  }

  public home = {
    subheadingSectionA: 'HOME',
    subheadingSectionB: 'ELECTRONICS',
    subheadingSectionC: 'GARDEN & TOOLS',
    subheadingSectionD: 'SPORTS & TRAVEL',
    subheadingSectionE: 'HEALTH & WELLNESS',
    buttonTextSectionA: 'BROWSE ALL HOME',
    buttonTextSectionB: 'BROWSE ALL ELECTRONICS',
    buttonTextSectionC: 'BROWSE ALL GARDEN & TOOLS',
    buttonTextSectionD: 'BROWSE ALL SPORTS & TRAVEL',
    buttonTextSectionE: 'BROWSE ALL HEALTH & WELLNESS',
    ariaLabel: 'Open '
  }

  public contact = {
    messageError: "Error! Something went wrong!",
    messageSuccess: "Thank you! Your message has been sent successfully. We will get back to you soon.",
    pageTitle: "Contact Form",
    submit: "Submit",
    messageLabel: "Message",
    messagePlaceholder: "Your message...",
    nameLabel: "Name",
    namePlaceholder: "Name...",
    emailLabel: "Email",
    emailPlaceholder: "Enter email..."
  }

  public footer = {
    contact: 'Contact',
    copyrights: "Copyright © 2024 Shoppermentor. All rights reserved.",
    label: "Footer navigation",
    privacyPolicy: 'Privacy policy'
  }

  public privacyPolicyPopup = {
    slogan: "This website uses cookies to ensure you get the best experience on our website.",
    variableName: "shoppermentorPrivacyPolicy"
  }

  public privacyPolicy = {
    pageTitle: "Privacy Policy",
    contents: "Contents",
    introduction: "Effective date: 20/5/2024. Welcome to shoppermentor.com. This privacy policy describes how we collect, use, and handle your information when you visit and use our website. By using shoppermentor.com, you consent to the collection and use of information in accordance with this policy.",
    sections: [
      {
        "subheadingRef": "subheadingRef1",
        "subheading": "Information Collection and Use",
        "section": "Google Analytics: We use Google Analytics to understand how our website is being used in order to improve the user experience. Google Analytics collects information such as how often users visit our site, what pages they visit when they do so, and what other sites they used prior to coming to our site. The information collected is used only to improve our site and services. Amazon Associates Program: As an Amazon Associate, we earn from qualifying purchases. When you click on an Amazon product link on our site, Amazon may collect information about your visit to track referrals."
      },
      {
        "subheadingRef": "subheadingRef2",
        "subheading": "How We Use Information",
        "section": "The information we collect is used in the following ways: To improve our website and services by understanding how they are used. To monitor the usage of our website. To detect, prevent, and address technical issues. To track referrals and earn commissions through the Amazon Associates Program."
      },
      {
        "subheadingRef": "subheadingRef3",
        "subheading": "Sharing Your Information",
        "section": "We do not share your personally identifiable information with third parties except as required by law. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above."
      },
      {
        "subheadingRef": "subheadingRef4",
        "subheading": "Your Rights",
        "section": "You have the right to access, edit, or delete your personal information at any time. If you wish to exercise these rights, please contact us at support@shoppermentor.com."
      },
      {
        "subheadingRef": "subheadingRef5",
        "subheading": "Data Security",
        "section": "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. However, no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security."
      },
      {
        "subheadingRef": "subheadingRef6",
        "subheading": "Children's Privacy",
        "section": "Our Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions."
      },
      {
        "subheadingRef": "subheadingRef7",
        "subheading": "Changes to This Privacy Policy",
        "section": "We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page."
      },
      {
        "subheadingRef": "subheadingRef8",
        "subheading": "Contact Us",
        "section": "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@shoppermentor.com"
      }
    ]
  }


  constructor() {

  }

}